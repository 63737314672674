<template>
  <div class="events">
    <navbar :showBack="false" />
    <div class="events-inner">
      <div class="type-tabs">
        <div
          v-for="item in tabs"
          class="tab"
          :key="item.route"
          :class="{ active: isActiveTab(item) }"
          @click="changeType(item)"
        >
          {{ item.label }}
        </div>
        <!-- <div
          v-if="userInfo.vip_level === 3"
          class="tab"
          :class="{ active: activeTab === TYPES.second }"
          @click="changeType(TYPES.second)"
        >
          间推粉丝
        </div> -->
      </div>
      <div class="lecture-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          finished-text="没有更多了"
          @load="fetchList"
        >
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/h5/events/${item.id}`)"
          >
            <div class="title">
              <span class="title-content">{{ item.title }}</span>
              <span class="price">悬赏：¥{{ item.price }}</span>
            </div>
            <div class="desc">{{ item.description }}</div>
            <div class="date">
              {{ item.cdatetime }}
              <span class="comment"
                >评论 <span>{{ item.comment_count }}</span> 条</span
              >
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <router-link to="publish-event">
    <div class="add-btn">
      <van-icon name="plus" size="30" color="#fff" />
      <span>发布寻根</span>
    </div>
    </router-link>
  </div>
</template>

<script>
import { List, Icon } from "vant";
import otherApis from "@/apis/other";
import Navbar from "@/components/h5/Navbar";

const TABS = [
  {
    label: "全部",
    route: "",
    left: "43px"
  }
];

export default {
  name: "Events",
  components: {
    "van-list": List,
    [Icon.name]: Icon,
    Navbar
  },
  data() {
    return {
      tabs: TABS,
      activeTab: TABS[0],
      page: 1,
      finished: false,
      loading: false,
      resultList: [],
      currApi: otherApis.articleIndex,
      catId: ""
    };
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab.route === tab.route;
    },

    async fetchList() {
      try {
        const {
          data: { article_list, max_page }
        } = await this.currApi({ page: this.page });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...article_list];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    changeType(tab) {
      this.activeTab = tab;
      this.catId = tab.route;
      // this.currApi =
      //   type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      this.refreshList();
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    }
  }
};
</script>

<style lang="scss" scope>
.events {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: calc(100vh - 150px);
  background-color: #f7f7f7;

  .add-btn {
    width: 150px;
    height: 150px;
    background: #ca0a15;
    border-radius: 50%;
    position: fixed;
    right: 32px;
    bottom: 400px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      font-size: 24px;
    }
  }

  .events-inner {
    margin: 40px;
    height: 100%;
    /* prettier-ignore */
    // min-height: calc(100vh - 96PX - 40px);
  }

  .type-tabs {
    display: flex;

    .tab {
      position: relative;
      width: 240px;
      height: 90px;
      background: #f7f7f7;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      margin-right: 10px;
      font-size: 44px;
      color: #8a574d;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: #ffffff;
        background-color: #CA0A15;
      }
    }
  }

  .lecture-list {
    background-color: #fff;
    padding: 40px;
    // min-height: 600px;

    // min-height: calc(100% - 90px);

    // height: calc(100% - 46px);
    // padding-top: 20px;

    // .list-header {
    //   height: 40px;
    //   background: #ffffff;
    //   border-radius: 4px;
    //   color: #333333;
    //   font-size: 14px;
    //   display: flex;
    //   justify-content: space-evenly;
    //   align-items: center;
    //   padding: 0 20px;
    // }

    .list-content {
      display: flex;
      flex-flow: column nowrap;
      // min-height: 100%;

      // overflow: auto;
      // height: calc(100% - 60px);
      // flex: none;

      .list-item {
        flex: none;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: 965px;
        height: 300px;
        background: #f7f7f7;
        border-radius: 10px;
        margin-top: 40px;
        padding: 40px;
        box-sizing: border-box;

        .title {
          font-size: 48px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          display: flex;
          justify-content: space-between;

          .title-content {
            // max-width: calc(100% - 370px);
            // width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .price {
            color: #ca0a15;
            font-size: 36px;
            white-space: nowrap;
          }
        }

        .desc {
          font-size: 44px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .date {
          font-size: 40px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b5b5b5;

          .comment {
            float: right;
            span {
              color: #ca0a15;
            }
          }
        }

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
